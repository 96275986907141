<template>
  <div>
    <router-view ref="routerView"></router-view>

    <notifications position="top center" width="500" closeOnClick />
  </div>
</template>


<script>

export default {
  name: "LoginLayout",
};
</script>
