<template>
    <div>
        <v-row class="b-clr" style="height: 200px;">
            <div class="">
                <div>
                    <!-- Search for small screen-->
                    <div class="container">
                        <div class="breadcrumbs-dark pb-0 pt-4" id="breadcrumbs-wrapper">
                            <!-- Search for small screen-->
                            <div class="container">
                                <div class="row  mb-5">
                                    <div class="col s10 m6 l6">
                                        <h2 :class="($store.getters.isMobile)?'text-white ml-4 mt-10':'text-white ml-16'">
                                            <span>Package Price</span></h2>
                                        <v-breadcrumbs :class="($store.getters.isMobile)?'text-white ml-n2 mt-n4':'text-white ml-10'">
                                            <v-breadcrumbs-item>Home
                                                <v-icon size="18" color="#f5f5f5">mdi-chevron-right</v-icon>
                                            </v-breadcrumbs-item>
                                            <v-breadcrumbs-item>Master
                                                <v-icon size="18" color="#f5f5f5">mdi-chevron-right</v-icon>
                                            </v-breadcrumbs-item>
                                            <v-breadcrumbs-item>Package Price
                                                <v-icon size="18" color="#f5f5f5">mdi-chevron-right</v-icon>
                                            </v-breadcrumbs-item>
                                            <v-breadcrumbs-item class="text-light">Add Package</v-breadcrumbs-item>
                                        </v-breadcrumbs>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </v-row>

        <!---main body--->

        <section :class="($store.getters.isMobile)?'mt-n12 mr-1 ml-1 mb-16':'m-t mr-4 ml-4'">
            <v-col class="col-md-12">
                <v-form v-model="valid" method="post" ref="form" @submit.prevent="submitAdForm">
                    <v-card>
                        <v-card-title>
                           Package Details
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-row>
                        <v-col cols="12" md="4" :class="($store.getters.isMobile)?'':'mt-2 ml-4'">
                            <v-text-field
                                dense
                                outlined
                                label="Project Name"
                                placeholder="Project Name"
                                v-model="form.name"
                            ></v-text-field>
                            <v-text-field
                                dense
                                outlined
                                label="Post Count"
                                placeholder="Post Count"
                                v-model="form.post_count"
                                :rules="[positiveNumberRule]"
                            ></v-text-field>
                            <v-text-field
                                dense
                                outlined
                                label="Discount(%)"
                                placeholder="Discount(%)"
                                v-model="form.discount"
                                :rules="[positiveNumberRule]"
                            ></v-text-field>
                            <v-text-field
                                dense
                                outlined
                                label="Final Price with GST"
                                placeholder="Final Price with GST"
                                v-model="form.gross_price"
                                :rules="[positiveNumberRule]"
                            ></v-text-field>
                            <v-select
                                :items="validity"
                                v-model="form.validity"
                                label="Package Validity(months)"
                                outlined
                                dense
                                clearable
                                item-text="label"
                                item-value="value"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="4" :class="($store.getters.isMobile)?'':'mt-2 ml-4'">
                            <v-select
                                :items="packages"
                                v-model="form.package_id"
                                label="Category"
                                outlined
                                dense
                                clearable
                                item-text="name"
                                item-value="id"
                            ></v-select>
                            <v-select :disabled="form.package_id !== 1 && form.package_id !== 2"
                                      :items="type"
                                      v-model="form.type"
                                      label="Type"
                                      outlined
                                      dense
                                      clearable
                                      item-text="label"
                                      item-value="value"
                            ></v-select>
                            <v-text-field
                                dense
                                outlined
                                label="Price"
                                placeholder="Price"
                                v-model="form.price"
                                :rules="[positiveNumberRule]"
                            ></v-text-field>
                            <v-text-field
                                dense
                                outlined
                                label="Final Price"
                                placeholder="Final Price"
                                v-model="form.net_price"
                                :rules="[positiveNumberRule]"
                            ></v-text-field>
                        </v-col>
                        </v-row>
                        <v-btn type="submit" class="ml-4 mb-4" color="success">Submit</v-btn>
                    </v-card>
                </v-form>
            </v-col>
        </section>
    </div>
</template>

<script>
import PackageDeal from "@/apis/PackageDeal.js";
import Packages from "@/apis/Packages";

export default {
    name: 'AddProject',

    data: () => ({
        packageDeal: [],
        packages: [],
        valid: false,
        form: {
            package_id: "",
            type: "",
            name: "",
            post_count: "",
            validity: "",
            price: "",
            discount: "",
            net_price: "",
            gross_price: "",
        },

        type: [
            {
                label: 'Agent',
                value: 'Agent'
            },
            {
                label: 'Owner',
                value: 'Individual'
            },
        ],
        validity: [
            {
                value: '1',
                label: '1 Months',

            },
            {
                 value: '3',
                label: '3 Months',

            },
            {
                value: '6',
                label: '6 Months',

            },
            {
                value: '12',
                label: '12 Months',

            },
        ],

    }),

    computed:{
        positiveNumberRule() {
            return value => {
                if (isNaN(value)) return 'Value must be a number';
                return true;
            };
        },
    },

    created() {
        if (this.$route.params.id) {
            this.form.id = this.$route.params.id;
            this.getPackageDeal();
        }
        this.getPackages();
    },

    methods: {
        getPackages() {
            Packages.index()
                .then((response) => {
                   this.packages = response.data.data;
                })
        },

        submitAdForm: async function () {
            this.loading = true;

            const fd = new FormData();

            for (var element in this.form) {
                fd.append(element, this.form[element]);
            }

            if (this.form.id) {
                fd.append("_method", "PATCH");
            }

            PackageDeal.store(fd, this.form.id)
                .then((response) => {
                    this.form.id = response.data.data.id;

                    this.$notify({
                        title: "Success",
                        text: "Package Added Successfully !!",
                        type: "success",
                        dangerouslyUseHTMLString: true,
                        position: 'top-right',
                        duration: 15000
                    });
                    this.loading = false;
                    this.$router.push({name: "package-price"});

                })
                .catch(errors => {
                    if (errors.response === undefined) {
                        location.reload();
                    }

                    var text = errors.response.data.message;
                    if (errors.response.data.errors) {
                        for (var value in errors.response.data.errors) {
                            if (text) text += "<br>";
                            text += errors.response.data.errors[value];
                        }
                    }

                    this.loading = false;

                    this.$notify({
                        title: "Error",
                        text: text,
                        type: "error",
                        dangerouslyUseHTMLString: true,
                        position: 'top-right',
                        duration: 15000
                    });
                });

        },

        getPackageDeal() {
            PackageDeal.show(this.form.id)
                .then((response) => {
                    var res = response.data.data;
                    for (var element in res) {
                        if (res[element] !== null && res[element] !== "null") {
                            this.form[element] = res[element];
                        } else {
                            this.form[element] = "";
                        }
                    }
                    this.form.validity = res.validity.toString();
                })
        },

    },

    watch: {},
};

</script>
