import Api from "./Api";

export default {
    async index(form) {
        return Api.get("/package-deals", {
            params: form
        });
    },

    async store(form, id) {
        if (id)
            return Api.post("/package-deals/" + id, form);

        return Api.post("/package-deals", form);
    },

    async show(id) {
        return Api.post("/package-deals/" + id);
    },

    async change_status(form, id) {
        if (id)
            return Api.post("/package-deal-status/" + id, form);

        return Api.post("/package-deal-status", form);
    },

    async update(form, id) {
        form.append("_method", "PATCH");
        return Api.post("/package-deals/" + id, form);
    },

    async delete(id) {
        return Api.delete("/package-deals/" + id);
    },

};
