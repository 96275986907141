<template>
    <div>
        <v-row class="b-clr" style="height: 200px;">
            <div class="">
                <div>
                    <!-- Search for small screen-->
                    <div class="container">
                        <div class="breadcrumbs-dark pb-0 pt-4" id="breadcrumbs-wrapper">
                            <!-- Search for small screen-->
                            <div class="container">
                                <div class="row  mb-5">
                                    <div class="col s10 m6 l6">
                                        <h2 :class="($store.getters.isMobile)?'text-white ml-4 mt-10':'text-white ml-16'">
                                            <span>Package Price</span></h2>
                                        <v-breadcrumbs :class="($store.getters.isMobile)?'text-white ml-n2 mt-n4':'text-white ml-10'">
                                            <v-breadcrumbs-item>Home
                                                <v-icon size="18" color="#f5f5f5">mdi-chevron-right</v-icon>
                                            </v-breadcrumbs-item>
                                            <v-breadcrumbs-item>Master
                                                <v-icon size="18" color="#f5f5f5">mdi-chevron-right</v-icon>
                                            </v-breadcrumbs-item>
                                            <v-breadcrumbs-item>Package Price
                                                <v-icon size="18" color="#f5f5f5">mdi-chevron-right</v-icon>
                                            </v-breadcrumbs-item>
                                            <v-breadcrumbs-item class="text-light">Add Package</v-breadcrumbs-item>
                                        </v-breadcrumbs>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </v-row>

        <!---main body--->

        <section :class="($store.getters.isMobile)?'mt-n12 mr-1 ml-1 mb-16':'m-t mr-4 ml-4'">
            <v-col class="mb-16">
                <v-data-table
                    fixed-header
                    :headers="headers"
                    class="elevation-2"
                    :items="packageDeal.data"
                    :options.sync="packageOptions"
                    :server-items-length="packageTotal"
                    :loading="loading"
                    hide-default-footer
                >
                    <template v-slot:[`item.action`]="{ item }">
                        <v-btn x-small color="primary" :to="'/add-packageDeal/'+item.id" class="mr-2"><v-icon size="18" class="mr-1"> mdi-pencil</v-icon>Edit</v-btn>
                        <v-btn x-small dark color="red" v-if="item.status != '0'" @click="changeStatus(item, 1)">
                            Deactivate
                        </v-btn>

                        <v-btn x-small dark color="success" class="mr-2" v-else @click="changeStatus(item, 0)">
                            Activate
                        </v-btn>

                    </template>

                    <template v-slot:top>
                        <v-row class="ma-2">
                            <v-card-title>Package Details : (Total: {{
                                    (packageDeal.meta) ? packageDeal.meta.total : 0
                                }})
                            </v-card-title>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" dark :class="($store.getters.isMobile)?'ml-4 mt-n2':'mt-5 mr-5'"
                                   @click="$router.push('/add-packageDeal/')">
                                Add
                            </v-btn>
                        </v-row>
                    </template>
                    <template v-slot:no-data>
                        <v-btn color="primary">
                            Reset
                        </v-btn>
                    </template>
                </v-data-table>
            </v-col>
            <v-col cols="12">
                <v-row class="justify-end">
                    <v-col cols="3">
                        <v-select dense :items="itemsPerPageOptions" label="Items per page"
                                  v-model="packageSearch.itemsPerPage" outlined @change="getPackage()"
                        ></v-select>
                    </v-col>
                    <v-pagination
                        v-model="packageSearch.page"
                        :length="(packageDeal.meta)?packageDeal.meta.last_page:0"
                        :total-visible="7"
                    ></v-pagination>
                </v-row>
            </v-col>
        </section>
    </div>
</template>


<script>
import PackageDeal from "@/apis/PackageDeal.js";

export default {
    name: "PackagePrice",

    data: () => ({
        packageDeal: [],
        valid: false,

        loading: false,
        packageOptions: {},
        packageTotal: 0,

        headers: [
            {text: 'Package Name', align: 'start', sortable: false, value: 'name',},
            {text: 'Category', align: 'start', sortable: false, value: 'package.name',},
            {text: 'Post Count', value: 'post_count'},
            {text: 'Package Validity(In months)', value: 'validity'},
            {text: 'Price', value: 'price'},
            {text: 'Discount(%)', value: 'discount'},
            {text: 'Final Price', value: 'net_price'},
            {text: 'Final Price with GST', value: 'gross_price'},
            {text: 'Action', value: 'action'},
        ],
        packageSearch: {
            page: 1,
            search: "",
            itemsPerPage: 10,
            sortBy: [],
            sortDesc: [],
        },
        itemsPerPageOptions: [20, 30, 40, 50, 100],
        last_page: 1,
        changeForm: {
            status: ""
        },
    }),

    mounted() {

    },

    created() {
    },

    computed: {},

    methods: {
        checkUserPermission: function (needle) {
            var ability = this.$store.state.ability;
            var length = ability?.length;
            for (var i = 0; i < length; i++) {
                if (ability[i] == needle) return true;
            }
            return false;
        },
        getPackage() {
            this.loading = true;
            PackageDeal.index(this.packageSearch)
                .then((response) => {
                    this.packageDeal = response.data;
                    this.loading = false;
                    this.packageTotal = response.data.meta.total;
                })
        },

        changeStatus(item, status) {
            this.loading = true;

            const fd = new FormData();
            fd.append('status', status);

            const action = status === 0 ? "Activate" : "Deactivate";

            if (confirm(`Are you sure you want to ${action} this package?`)) {
                PackageDeal.change_status(fd, item.id)
                    .then(() => {
                        const message =
                            status === 0
                                ? "Activated Package Successfully!"
                                : "Deactivated Package Successfully!";

                        this.$notify({
                            title: "Success",
                            text: message,
                            type: "success",
                            duration: 10000,
                        });

                        this.getPackage();
                        this.loading = false;
                    })
                    .catch((errors) => {
                        if (errors.response === undefined) {
                            location.reload();
                        }

                        let text = errors.response.data.message;
                        if (errors.response.data.errors) {
                            for (const value in errors.response.data.errors) {
                                if (text) text += "<br>";
                                text += errors.response.data.errors[value];
                            }
                        }

                        this.$notify({
                            title: "Error",
                            text: text,
                            type: "error",
                            dangerouslyUseHTMLString: true,
                            position: "top-right",
                            duration: 15000,
                        });
                        this.loading = false;
                    });
            } else {
                this.loading = false;
            }
        },

        deletePackage(packageDeal) {
            if (confirm("Are You Sure ?")) {
                PackageDeal.delete(packageDeal.id)
                    .then(() => {
                        this.getPackage();
                        this.$notify({
                            title: "Success",
                            text: "Package Deleted Successfully !!",
                            type: "success",
                            duration: 10000,
                        });
                    })
            }
        },
    },

    watch: {
        "packageSearch.page": function () {
            this.getPackage();
        },
        packageOptions: {
            handler(val) {
                this.packageSearch.page = val.page;
                this.packageSearch.itemsPerPage = val.itemsPerPage;
                this.packageSearch.sortBy = val.sortBy;
                this.packageSearch.sortDesc = val.sortDesc;
                this.getPackage();
            },
            deep: true,
        },
    },
};

</script>
