import Vue from 'vue';
import App from './layout/App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import Notifications from 'vue-notification';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import {abilitiesPlugin} from '@casl/vue'
import JsonExcel from "vue-json-excel";
import DatetimePicker from 'vuetify-datetime-picker'
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

Vue.component('VuePhoneNumberInput', VuePhoneNumberInput);

Vue.config.productionTip = false

Vue.component("downloadExcel", JsonExcel);


Vue.use(Notifications);
Vue.use(CKEditor);
Vue.use(abilitiesPlugin, store.state.ability);
Vue.use(DatetimePicker);


import "./assets/main.css";

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.getters.loggedIn) {
      if (to.path != 'null')
        store.state.nextUrl = to.path;

      next({
        name: 'login',
        query: {
          auth: "login",
        }
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
