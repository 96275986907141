import axios from "axios";
import store from "../store/index";

let Api = axios.create({
    // baseURL: "http://api.ipd.localenv/api/",
    // baseURL: "https://api-test.indiapropertydekho.com/api/",
    baseURL: "https://api.indiapropertydekho.com/api/",
});

Api.defaults.withCredentials = true;

if (store.state.token)
    Api.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.token;

Api.defaults.headers.common["Accept"] = "application/json";
Api.defaults.headers.common["Content-Type"] = "multipart/form-data";

Api.interceptors.response.use(function (response) {
    return response
}, function (error) {
    if (error.response.status == 401) {
        localStorage.removeItem("adminLogin");
        location.href = '/';
    }
    return Promise.reject(error)
})

export default Api;
