import Api from "./Api";
import Csrf from "./Csrf";

export default {
    async login(form) {
        await Csrf.getCookie();
        return Api.post("/validate-password", form);
    },

    async logout() {
        await Csrf.getCookie();
        return Api.post("/logout");
    },


    auth() {
        return Api.get("/user");
    }
};
