<template>
  <div>
    <component v-bind:is="layout"></component>
  </div>
</template>


<script>

import AppLayout from "./AppLayout.vue";
import LoginLayout from "./LoginLayout.vue";

export default {
  name: "App",

  components: {
    'app-layout': AppLayout,
    'login-layout': LoginLayout
  },

  computed: {
    layout() {
      return (this.$route.meta.layout) ? this.$route.meta.layout : this.$store.getters.layout
    }
  },

  created() {
    this.title = this.$route.meta.title;

    // window.axios.interceptors.request.use(
    //     (config) => {
    //       const token = this.$store.state.token;
    //
    //       if (config.method === 'get') {
    //         config.url = config.url + '?api_token=' + token;
    //       } else {
    //         config.headers.Authorization = "Bearer " + token;
    //       }
    //       return config;
    //     }
    // )
  },
};
</script>
